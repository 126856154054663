// import './canvasNoise';
// // import './displacment';
// import './post';
document.addEventListener('DOMContentLoaded', () => {
  $('.fade-in').fadeOut(1000);

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
});
